import { Layout, message } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import { Content } from "antd/lib/layout/layout";
import { useDispatch } from "react-redux";
import StylesControlByRadio from "../../utils/dataAnalysis/Plots/CustomMapboxControls/StylesControl/StylesControlByRadio";
import {
  DATEFORMAT,
  getStatsPointMap311,
  getStatsPointMapRatReport,
} from "../../utils/utilData";
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import { getPopupDOMContentForPointsOfInterestRats } from "../surveyForms/getPopupDOMContentForPointsOfInterestRats";
import Filters311 from "../analysis/Filters311";
import LegendControl from "../../utils/dataAnalysis/Plots/CustomMapboxControls/LegendControl/LegendControl";
import LayersControlByCheckbox_SimplePointMap from "../../utils/dataAnalysis/Plots/CustomMapboxControls/LayersControl/LayersControlByCheckbox_SimplePointMap";

const { Footer } = Layout;

// function getLanguage() {
//     let varLanguage = 'en'
//     if (localStorage.getItem("language") === undefined) {
//         const lang = window.navigator.language;
//         if (lang && lang.startsWith('es')) {
//             varLanguage = 'es'
//         }
//     } else if (localStorage.getItem("language") === 'es') {
//         varLanguage = 'es'
//     }
//     return varLanguage;
// }

const Home = (props) => {
  // const REACT_APP_NAME = process.env.REACT_APP_NAME;
  // const REACT_APP_TEXT_A1 = getLanguage() === 'es' ? process.env.REACT_APP_TEXT_A1_ES : process.env.REACT_APP_TEXT_A1;
  // const REACT_APP_TEXT_A2 = getLanguage() === 'es' ? process.env.REACT_APP_TEXT_A2_ES : process.env.REACT_APP_TEXT_A2;
  // const REACT_APP_TEXT_B1 = getLanguage() === 'es' ? process.env.REACT_APP_TEXT_B1_ES : process.env.REACT_APP_TEXT_B1;

  const intl = useIntl();
  const optionsValuesStylesControl = ["streets", "satellite-streets"];

  const mapContainerRef = useRef();
  const activeAreaPointsRef = useRef([]);
  const [mapStyle] = useState({
    label: "Satellite streets",
    value: "satellite-streets",
    url: "mapbox://styles/mapbox/satellite-streets-v11",
  });
  const dispatch = useDispatch();
  const [searchLoad, setSearchLoad] = useState(false);
  const [searchLoad311, setSearchLoad311] = useState(false);
  const [searchLoadRatReport, setSearchLoadRatReport] = useState(false);

  const [lastControlEvent, setLastControlEvent] = useState(null);
  const [stylesControl, setStylesControl] = useState(
    new StylesControlByRadio(optionsValuesStylesControl, mapStyle, null, null)
  );

  const initActiveVariablesWithColors = [
    intl.formatMessage({ id: "Home.initActiveVariablesWithColors.311_rat" }),
    intl.formatMessage({
      id: "Home.initActiveVariablesWithColors.rat_report",
    }),
  ];
  const initActiveColorsWithVariables = [
    "#f24f2e", //red
    "#f8e93b", // orange
  ];

  const activeVariablesIndexesRef = useRef([0, 1]);

  const getActiveColorsWithVariables = (activeVariables) => {
    const activeColorsWithVariables = initActiveVariablesWithColors
      .map((initActiveVariable, initActiveIndex) => {
        if (activeVariables.includes(initActiveVariable)) {
          return initActiveColorsWithVariables[initActiveIndex];
        } else {
          return null;
        }
      })
      .filter((item) => item !== null);

    return activeColorsWithVariables;
  };

  const getActiveVariablesIndexes = (activeVariables) => {
    const activeVariablesIndexes = initActiveVariablesWithColors
      .map((initActiveVariable, initActiveIndex) => {
        if (activeVariables.includes(initActiveVariable)) {
          return initActiveIndex;
        } else {
          return null;
        }
      })
      .filter((item) => item !== null);

    return activeVariablesIndexes;
  };

  const legendTitle = intl.formatMessage({ id: "Home.Legend.title" });
  const mapPlotHeight = 700;
  const legendControlRef = useRef(
    new LegendControl(
      initActiveVariablesWithColors,
      initActiveColorsWithVariables,
      legendTitle,
      0.8 * mapPlotHeight
    )
  );
  const layersControlRef = useRef(new LayersControlByCheckbox_SimplePointMap(
    initActiveVariablesWithColors,
    initActiveVariablesWithColors,
    setLastControlEvent
  ));

  const [data311, setData311] = useState(null);
  const [dataRatReport, setDataRatReport] = useState(null);
  const [isDataReady, setIsDataReady] = useState(false);
  const [initDateRange, setInitDateRange] = useState(null);

  function removeMarkers(markers) {
    if (markers) {
      markers.forEach(function (marker) {
        marker.remove(); // Also show internal element
      });
    }
  }

  useEffect(() => {
    setStylesControl(
      new StylesControlByRadio(optionsValuesStylesControl, mapStyle, null, null)
    );
    //eslint-disable-next-line
  }, [mapStyle]);

  useEffect(
    function ue_lastControlEvent() {
      if (lastControlEvent === null) return;

      switch (lastControlEvent.event) {
        case "layersControl":
          const activeVariablesWithColors = lastControlEvent.data;
          const activeColorsWithVariables = getActiveColorsWithVariables(
            activeVariablesWithColors
          );
          activeVariablesIndexesRef.current = getActiveVariablesIndexes(
            activeVariablesWithColors
          );

          //updating legendControlRef.current
          legendControlRef.current.update(
            activeVariablesWithColors,
            activeColorsWithVariables
          );

          //fit map bounds to visible variables
          updateMap();

          setLastControlEvent(null);
          break;

        default:
          const errorMsg = `Error: "${lastControlEvent.event}" is not an implemented custom mapbox control!`;
          console.log(errorMsg);
          message.error(errorMsg);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastControlEvent]
  );

  const updateLegendControlWhenDataChanged = () => {
    let indexesToUpdate = [];

    [data311, dataRatReport].forEach((data, index) => {
      if (data.points.length > 0) indexesToUpdate.push(index);
    });

    let activeVariablesWithColors = [];
    let activeColorsWithVariables = [];

    indexesToUpdate.forEach((indexToUpdate) => {
      activeVariablesWithColors.push(
        initActiveVariablesWithColors[indexToUpdate]
      );
      activeColorsWithVariables.push(
        initActiveColorsWithVariables[indexToUpdate]
      );
    });

    legendControlRef.current.update(
      activeVariablesWithColors,
      activeColorsWithVariables
    );
  };

  const updateLayersControlWhenDataChanged = () => {
    layersControlRef.current.update(
      initActiveVariablesWithColors, 
      initActiveVariablesWithColors.filter((_, initActiveIndex) => 
      activeVariablesIndexesRef.current.includes(initActiveIndex)
    ))
  };

  useEffect(() => {
    if (data311 !== null && dataRatReport !== null) {
      setIsDataReady(true);
    } else {
      if (isDataReady) {
        setIsDataReady(false);
      }
    }

    //eslint-disable-next-line
  }, [data311, dataRatReport]);

  useEffect(() => {
    if (isDataReady) {
      if (mapRef.current === null) {
        fillMap();
      } else {
        updateMap();
        updateLegendControlWhenDataChanged();
        updateLayersControlWhenDataChanged();
      }
      if (data311?.dateIni && data311?.dateEnd) {
        setInitDateRange([data311.dateIni, data311.dateEnd]);
      }
    } else if (mapRef.current === null) {
      onFinish({});
    }

    //eslint-disable-next-line
  }, [isDataReady]);

  useEffect(() => {
    setSearchLoad(searchLoad311 && searchLoadRatReport);
  }, [searchLoad311, searchLoadRatReport]);

  const initSearch = () => {
    activeVariablesIndexesRef.current = [0,1];
    setSearchLoad311(true);
    setSearchLoadRatReport(true);
    setData311(null);
    setDataRatReport(null);
  };

  const onFinish = (values) => {
    initSearch();
    let filterSearch = {};
    if (
      "dateRange" in values &&
      values.dateRange !== undefined &&
      values.dateRange !== null
    ) {
      filterSearch.dateStart = values.dateRange[0].format(DATEFORMAT);
      filterSearch.dateEnd = values.dateRange[1].format(DATEFORMAT);
    }
    dispatch(getStatsPointMap311(filterSearch)).then((data) => {
      if (data) {
        setSearchLoad311(false);
        setData311(data);
      }
    });
    dispatch(getStatsPointMapRatReport(filterSearch)).then((data) => {
      if (data) {
        setSearchLoadRatReport(false);
        setDataRatReport(data);
      }
    });
  };

  const mapRef = useRef(null);
  const data311MarkersRef = useRef([]);
  const dataRatReportMarkersRef = useRef([]);

  const fitBoundsToAreaBorders = () => {
    const areaBordersPoints = [];

    if (data311.projectId === dataRatReport.projectId) {
      areaBordersPoints.push(data311.area);
    } else {
      areaBordersPoints.push(data311.area);
      areaBordersPoints.push(dataRatReport.area);
    }

    function collectPoints(geojson1, geojson2) {
      const points = [];
    
      // Collect points from the first GeoJSON
      if (geojson1?.features) {
        geojson1.features.forEach(feature => {
          if (feature.geometry.type === 'Point') {
            points.push(feature.geometry.coordinates);
          } else if (feature.geometry.type === 'MultiPoint') {
            feature.geometry.coordinates.forEach(coord => {
              points.push(coord);
            });
          } else if (feature.geometry.type === 'Polygon') {
            feature.geometry.coordinates[0].forEach(coord => {
              points.push(coord);
            });
          } else if (feature.geometry.type === 'MultiPolygon') {
            feature.geometry.coordinates.forEach(polygon => {
              polygon[0].forEach(coord => {
                points.push(coord);
              });
            });
          }
        });
      }
    
      // Collect points from the second GeoJSON
      if (geojson2?.features) {
        geojson2?.features.forEach(feature => {
          if (feature.geometry.type === 'Point') {
            points.push(feature.geometry.coordinates);
          } else if (feature.geometry.type === 'MultiPoint') {
            feature.geometry.coordinates.forEach(coord => {
              points.push(coord);
            });
          } else if (feature.geometry.type === 'Polygon') {
            feature.geometry.coordinates[0].forEach(coord => {
              points.push(coord);
            });
          } else if (feature.geometry.type === 'MultiPolygon') {
            feature.geometry.coordinates.forEach(polygon => {
              polygon[0].forEach(coord => {
                points.push(coord);
              });
            });
          }
        });
      }
    
      return points.map(coordinates => turf.point(coordinates));
    }
        
    // Create GeoJSON feature collection
    const points = collectPoints(
      areaBordersPoints?.[0],
      areaBordersPoints?.[1]
    );
    if (points?.length === 0) {
      const errorMsg = `Error: Project's area must have points! The error occurred at function 'fitBoundsToAreaBorders'.`; 
      console.log(errorMsg);
      throw new Error(errorMsg);
    }
    const featureCollection = turf.featureCollection(points);
    // Calculate the bounding box
    const bbox = turf.bbox(featureCollection);

    mapRef.current.fitBounds(bbox, { padding: 50 });
  };

  const setProjectsAreaBorders = () => {
    //add border for projects area
    const projectBorderColor = "#f4ff6d";
    if (data311.projectId === dataRatReport.projectId) {
      //one common area
      mapRef.current.addSource("project_region", {
        type: "geojson",
        data: data311.area,
      });
      mapRef.current.addLayer({
        id: "project_region",
        type: "line",
        source: "project_region",
        layout: {
          visibility: "visible",
        },
        paint: {
          "line-color": projectBorderColor,
          "line-width": 2,
        },
      });
    } else {
      //each with different border area, one for each project
      mapRef.current.addSource("project_region_data311", {
        type: "geojson",
        data: data311.area,
      });
      mapRef.current.addLayer({
        id: "project_region_data311",
        type: "line",
        source: "project_region_data311",
        layout: {
          visibility: "visible",
        },
        paint: {
          "line-color": projectBorderColor,
          "line-width": 2,
        },
      });
      mapRef.current.addSource("project_region_dataRatReport", {
        type: "geojson",
        data: dataRatReport.area,
      });
      mapRef.current.addLayer({
        id: "project_region_dataRatReport",
        type: "line",
        source: "project_region_dataRatReport",
        layout: {
          visibility: "visible",
        },
        paint: {
          "line-color": projectBorderColor,
          "line-width": 2,
        },
      });
    }
  };

  const updateActiveAreaPointsRef = () => {
    activeAreaPointsRef.current = [];

    if (
      activeVariablesIndexesRef.current.includes(0) &&
      data311?.points?.length > 0
    ) {
      data311?.points.forEach((item) =>
        activeAreaPointsRef.current.push([item.longitude, item.latitude])
      );
    }

    if (
      activeVariablesIndexesRef.current.includes(1) &&
      dataRatReport?.points?.length > 0
    ) {
      dataRatReport?.points.forEach((item) =>
        activeAreaPointsRef.current.push([item.longitude, item.latitude])
      );
    }
  };

  const fitBoundsToActiveAreaPoints = () => {
    //quit if there are not points
    if (activeAreaPointsRef.current.length === 0) return;

    // Create GeoJSON feature collection
    const points = activeAreaPointsRef.current.map((coord) =>
      turf.point(coord)
    );
    const featureCollection = turf.featureCollection(points);
    // Calculate the bounding box
    const bbox = turf.bbox(featureCollection);

    mapRef.current.fitBounds(bbox, { padding: 50 });
  };

  const setActiveMarkers = () => {
    if (data311MarkersRef.current.length > 0) {
      removeMarkers(data311MarkersRef.current);
      data311MarkersRef.current = [];
    }
    if (dataRatReportMarkersRef.current.length > 0) {
      removeMarkers(dataRatReportMarkersRef.current);
      dataRatReportMarkersRef.current = [];
    }

    if (activeVariablesIndexesRef.current.includes(0)) {
      data311?.points.forEach((point) => {
        const feature = {
          //important: the order in which the keys of properties are set, it the same order in which
          //the popup information appears
          properties: {
            name: intl.formatMessage({
              id: "Home.initActiveVariablesWithColors.311_rat",
            }),
            date: point?.date_created,
            status: point?.status,
            city: point?.city,
          },
        };
        const marker = new mapboxgl.Marker({
          color: data311?.color,
          scale: 1,
          draggable: false,
          pitchAlignment: "auto",
          rotationAlignment: "auto",
          // iconImage: circleIconsUrl,
          //element: createCircle(20, "#f24f2e"),
        });
        marker
          .setLngLat([point?.longitude, point?.latitude])
          .setPopup(
            new mapboxgl.Popup({ closeButton: false }) // add popups
              .setDOMContent(getPopupDOMContentForPointsOfInterestRats(feature))
          )
          .addTo(mapRef.current);

        data311MarkersRef.current.push(marker);
      });
    }

    if (activeVariablesIndexesRef.current.includes(1)) {
      dataRatReport?.points.forEach((point) => {
        const feature = {
          //important: the order in which the keys of properties are set, it the same order in which
          //the popup information appears
          properties: {
            name: intl.formatMessage({
              id: "Home.initActiveVariablesWithColors.rat_report",
            }),
            photo: point?.photo,
            date: point?.date_created,
            description: point?.description,
          },
        };
        const marker = new mapboxgl.Marker({
          color: dataRatReport?.color,
          scale: 1,
          draggable: false,
          pitchAlignment: "auto",
          rotationAlignment: "auto",
          // iconImage: circleIconsUrl,
        });
        marker
          .setLngLat([point?.longitude, point?.latitude])
          .setPopup(
            new mapboxgl.Popup({ closeButton: false }) // add popups
              .setDOMContent(getPopupDOMContentForPointsOfInterestRats(feature))
          )
          .addTo(mapRef.current);

        dataRatReportMarkersRef.current.push(marker);
      });
    }
  };

  const updateMap = () => {
    updateActiveAreaPointsRef();

    if (activeAreaPointsRef.current.length > 0) {
      fitBoundsToActiveAreaPoints();
    } else {
      fitBoundsToAreaBorders();
    }

    setActiveMarkers();
  };

  const fillMap = () => {
    // const geojson = data?.area;
    const latitude = 0;
    const longitude = 0;
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: mapStyle.url,
      center: [longitude, latitude],
      zoom: 2,
    });

    // Add navigation control (the +/- zoom buttons)
    mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-right");
    mapRef.current.addControl(new mapboxgl.FullscreenControl(), "top-right");
    mapRef.current.addControl(layersControlRef.current, "top-right");
    mapRef.current.addControl(stylesControl, "top-right");
    mapRef.current.addControl(legendControlRef.current, "bottom-left");
    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
    });
    mapRef.current.addControl(geolocate);

    mapRef.current.on("load", () => {
      mapRef.current.resize();
      setProjectsAreaBorders();
      updateMap();
    });

    return () => mapRef.current.remove();
  };

  return (
    <Layout className="site-layout">
      <Content style={{ margin: "0 16px" }}>
        <Filters311
          onFinish={onFinish}
          searchLoad={searchLoad}
          initDateRange={initDateRange}
        />
        <div
          className="map-container"
          ref={mapContainerRef}
          style={{ height: mapPlotHeight, width: "100%", clear: "both" }}
        />
      </Content>
      <Footer style={{ textAlign: "center" }}>
        ©{new Date().getFullYear()}{" "}
        <FormattedMessage
          id="label.home_h29"
          defaultMessage="Powered By Vector Analytica,Inc"
        />
      </Footer>
    </Layout>
  );
};

export default Home;
